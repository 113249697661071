<template>
  <List :waiting-only="true" />
</template>

<script>
import List from '@/components/pages/search_quest/List';

export default {
  name: 'WaitingList',
  components: { List }
};
</script>
